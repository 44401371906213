import ScenarioResource from 'common/services/resources/scenario.js'
import DeviceResource from 'common/services/resources/device.js'
import TimeService from 'common/services/time.js'
import ArrayService from 'common/services/array.js'
import WindowService from 'common/services/window.js'
import DevicesList from 'common/components/devices-list/devices-list.vue'
import {required} from 'vuelidate/lib/validators'
import TelControl from 'common/components/widget/control-device/tel-control/tel-control.vue'
import OnOffControl from 'common/components/widget/control-device/on-off/on-off.vue'
import DevicesWidget from 'common/components/widget/widget.vue'
//import RoomsSelect from 'common/components/rooms-select/rooms-select.vue'
import Email from 'common/components/email/email.vue'
import Push from 'common/components/push/push.vue'
import Sms from 'common/components/sms/sms.vue'
import DeleteModal from 'common/components/delete-modal/delete-modal.vue'
import ControlDevice from 'common/components/widget/control-device/control-device.vue'
import PhoneNumberService from 'common/services/phone-number.js'
import VueSlider from 'vue-slider-component'
import 'vendor/vue-slider-component/theme/default.css'
import Language from 'common/services/language.js'
import Global from 'common/services/resources/global.js'

export default { 
	components: {VueSlider,DevicesList, TelControl, OnOffControl,DevicesWidget,ControlDevice, DeleteModal, Email, Push, Sms},
	props: ['user','config','rooms','plant', 'models', 'scenario', 'scenarios', 'index', 'color','devices','subDevices', 'title','defaultRooms','appConstants','services'],
	data:
		function () {
			return {
					CONSTANTS:{
						DEFAULT_CAPABILITIES : [
							{'capabilityName': 'status', 'value': 'on'},
							{'capabilityName': 'status', 'value': 'off'}
						],
						NEXT_BUTTON: this.$gettext('Next'),
						VALIDATE_BUTTON: this.$gettext('Validate'),
						EMPTY_NAME_ERROR: this.$gettext('The scenario name is empty'),
						NO_ACTION_ERROR: this.$gettext('Please add an action'),
						SCENARIO_ADD_SUCCESS: this.$gettext('The scenario has been added successfully'),
						SCENARIO_ADD_ERROR : this.$gettext('Unable to add the scenario'),
						SCENARIO_EDIT_SUCCESS: this.$gettext('The scenario has been edited successfully'),
						SCENARIO_EDIT_ERROR : this.$gettext('Unable to edit the scenario'),
						CONTENT_TEXT_DELETE_MODAL : this.$gettext('Remove this'),
						TEMPERATURE_ERROR : this.$gettext('Fill the select and give a number less than 50'),
						NOTIFICATION : _upperFirst(this.$gettext('notification')),
						ALREADY_NOTIFICATION : this.$gettext('You already have a push notification as action'),
						NO_NETWORK: this.$gettext('Inactive internet connection! Please activate your internet connection in order to execute this action'),
						ALREADY_CAMERA: this.$gettext('At the moment you can display only one camera at a time'),
						ZERO_CREDIT: this.$gettext('You no longer have credit for this action'),
						UNLIMITED_CREDIT: this.$gettext('Credit : <b>unlimited</b>'),
						REMAINING_CREDIT: this.$gettext('Credit : <b>%{s}</b>'),
						AVAILABLE_SYSTEMS:[
											'energy',
											'security'
										]
					},
					time: null,
					modal2: false,
					step: 1,
					stepError: {
									state: false,
									txt: ''
								},
					manuallyDialog: false,
					manualTriggerClickDialog: false,
					typeActionDialog: false,
					deviceActionDialog: false,
					genericActionDialog: false,
					serviceActionDialog: false,
					chooseActionDialog:false,
					emailActionDialog:false,
					smsActionDialog: false,
					pushActionDialog:false,
					typeTriggerDialog: false,
					actionTemperatureDialog: false,
					scheduleDialog: false,
					triggerDeviceDialog: false,
					triggerServiceDialog: false,
					actionCmrDialog: false,
					actionTelDialog: false,
					actionTrfDialog: false,
					chooseAlarmTriggerDialog: false,
					triggerValue:false,
					valueCapabilityDialog:false,
					name:'',
					smsAction: '',
					roomId: '',
					temperatureSign: '',
					titleOnOff: this.$gettext(''),
					moduleType:'',
					currentAction:{},
					capabilitiesStatesForList: [],
					currentTrigger:{},
					selectedCapability:{},
					actions: [],
					triggers: [],
					use: '',
					deleteDialog:
					{
						isOpen: false,
						title:'',
						contentTxt: '',
						currentDeletedArray: [],
						currentDeletedElt: {
							type:''
						}
					},
					alarm:{
						capability:''
					},
					scheduleModal:
					{
						selectedTypeO: {value:'ALL'},
						selectedType: 'ALL',
						type :[
							{
								text: this.$gettext('everyday').charAt(0).toUpperCase() + this.$gettext('everyday').slice(1),
								value: 'ALL'
							},
							{
								text: this.$gettext('Only these days').charAt(0).toUpperCase() + this.$gettext('Only these days').slice(1),
								value: 'ONLY_DAYS'
							},
						],

						selectedDays: ['SUNDAY','MONDAY','TUESDAY','WEDNESDAY','THURSDAY','FRIDAY','SATURDAY'],
						days: [
							{ text: this.$gettext('sun'), value: 'SUNDAY'},
							{ text: this.$gettext('mon'), value: 'MONDAY'},
							{ text: this.$gettext('tue'), value: 'TUESDAY'},
							{ text: this.$gettext('wed'), value: 'WEDNESDAY'},
							{ text: this.$gettext('thu'), value: 'THURSDAY'},
							{ text: this.$gettext('fri'), value: 'FRIDAY'},
							{ text: this.$gettext('sat'), value: 'SATURDAY'}
						],
						modalTimepicker: false,
						timeValue: TimeService.getCurrentTime(),
						repeat: false
					},
					valueTemperature: 0,
					signChoice: [
						{ 
							text:  this.$gettext('Greater than'),
							value: 'Greater than'
						},
						{ 
							text:  this.$gettext('Less than'),
							value: 'Less than'
						}
							],
					email: '',
					sms: '',
					displaySMSChoice: true,
					loading: false,
					stepper: [
						{'number' : 1, 'title' : 'Actions'},
						{'number' : 2, 'title' : 'Triggers'},
						{'number' : 3, 'title' : 'Name'},
						{'number' : 4, 'title' : 'Room'}
					],

					currentElementForSpecificDialog:{},
					//Speed dialog
					speedDialog: false,
					sliderValue:0,
					sliderOptions: {
					  dotSize: 14,
					  eventType: 'auto',
					  min: 0,
					  max: 3,
					  interval: 1,
					  clickable: true,
					  disabled: false,
					  tooltip: false,
					  piecewise: true,
					  speed: 0.5,
					  processStyle: {
						"backgroundColor": "#3C3C3C"
					  },
					  piecewiseActiveStyle: {
						"backgroundColor": "#fff"
					  }  
					},
					colorTemperatureSlider: 
					{
					  value: 0,
					  options: {
						height: 6,
						interval: 1,
						clickable: true,
						tooltip: true,
						railStyle:
						{
						}
					  }
					}
			}
		},
	validations: {
		name: {
			required
		}
	},
	computed: {
		filteredActionDevicesList: function () {
			return DeviceResource.filterDevicesInRooms(this.rooms,'device','executable',['cmr','tel','trf']);
		},		
		filteredTriggerDevicesList: function () {
			return DeviceResource.filterDevicesInRooms(this.rooms,'device','can_trigger');
		},
		filteredServices: function () {
			return DeviceResource.filterDevicesInRooms(this.rooms,'service');
		}
	},
	created: function () {
		this.eventHub.addEvent('telTrigger', this.telTrigger);
		this.eventHub.$emit('changeOrientation', 'portrait');
		this.eventHub.addEvent('createScenarioItem', this.createScenarioItem);
		if(this.scenario){
			if(this.scenario.actions)
				this.actions = JSON.parse(JSON.stringify(this.scenario.actions));
			this.name = this.scenario.name;
			this.roomId = this.scenario.locationId;
			if(this.scenario.triggers){
				this.triggers = JSON.parse(JSON.stringify(this.scenario.triggers));
			}
		}
	},
	destroyed: function () {
		//this.eventHub.$emit('changeOrientation', 'user');
	},
	watch: {
		 'scheduleModal.selectedType': function (newValue) {
			 for (var i = 0; i < this.scheduleModal.days.length; i++)
			 {
				 this.scheduleModal.days[i].selected = true;
    		 }
		}
	},
	methods: {
		
		getSubTitle: function(a, subTitlesObj){
			return (subTitlesObj[a.infos.capability+'-'+a.infos.value]?subTitlesObj[a.infos.capability+'-'+a.infos.value].subTitle:
			(subTitlesObj[a.infos.value]?subTitlesObj[a.infos.value].subTitle:
			(subTitlesObj[a.infos.capability]?subTitlesObj[a.infos.capability].subTitle:a.infos.capability +' - '+ a.infos.value)));
		},
		
		shortkeyEnter: function(){
			if(this.step == 3)
			{
				this.validate();
			}
			else if(this.step < 4)
			{
				this.nextStep();
			}
		},


		nextStep: function(){
			if(this.step==1)
			{
				if(this.actions.length==0)
				{
					this.stepError.state = true;
					this.stepError.txt = this.CONSTANTS.NO_ACTION_ERROR;
					this.eventHub.$emit('displayAlert',  this.CONSTANTS.NO_ACTION_ERROR);
					return false;
				}
				this.stepError.state = false;
				this.step = 2;
				//this.manuallyDialog = true;
			}
			else
				this.step=parseInt(this.step)+1;
		},
		backStep: function(){
			if(this.step == 2){
				this.triggerValue = false;
			}
			this.step=parseInt(this.step)-1;
		},

		confirmManually: function(confirm){
			if(confirm)
			{
				this.step = 2;
			}
			else
			{
				this.step = 3;
			}
			this.manuallyDialog = false;
		},

		openDeleteDialog: function(arr, a, type, index){
			this.deleteDialog.currentDeletedElt = {
				name : a.infos.name,
				type: type,
				index: index

			}
			switch(type)
			{
				case 'action':
								this.deleteDialog.title = this.$gettext('Remove this action');
								if(a.type == 'push')
									this.deleteDialog.contentTxt = this.$gettext('Remove this action \'notification\'?');
								else if(a.type == 'email')
									this.deleteDialog.contentTxt = this.$gettext('Remove this action \'email\'?');
								else
									this.deleteDialog.contentTxt = this.$gettextInterpolate(this.$gettext('Remove this action \'%{s}\'?'), {s: a.infos.name});
								break;
				case 'trigger':
								this.deleteDialog.title = this.$gettext('Remove this trigger');
								this.deleteDialog.contentTxt = this.$gettextInterpolate(this.$gettext('Remove this trigger \'%{s}\'?'), {s: (a.type=='schedule'?this.$gettext(a.type):a.infos.name)});
								break;

			}
			this.deleteDialog.currentDeletedArray = arr;
			this.deleteDialog.isOpen = true;
		},

		confirmDeleteElement: function(){
			this.eventHub.$emit('setLoading',false);
			this.deleteDialog.currentDeletedArray.splice(this.deleteDialog.currentDeletedElt.index, 1);
			this.deleteDialog.isOpen = false;
		},

		/* ACTION */
		addAction: function(){
			this.typeActionDialog = true;
		},

		selectTypeAction: function(typeAction){
			this.typeActionDialog = false;
			this[typeAction+'ActionDialog'] = true;
		},

		clickOnDevicesListItem: function(action,item,moduleType)
		{
			this[action](item,moduleType);
		},

		chooseItemToAction: function(element,moduleType,trigger){	
			this.moduleType = moduleType;
			if(element == 'email'){
				if(this.user.resourcesCount.email==0){
					this.eventHub.$emit('displayAlert', this.CONSTANTS.ZERO_CREDIT);
				}
				this.genericActionDialog = false;
				this.emailActionDialog = true;
			}
			else if(element == 'sms'){
				if(this.user.resourcesCount.sms==0){
					this.eventHub.$emit('displayAlert', this.CONSTANTS.ZERO_CREDIT);
				}
				this.genericActionDialog = false;
				this.smsActionDialog = true;
			}
			else if(element == 'push'){
				if(_findIndex(this.actions, function(o) { return o.type == 'push'; }) != -1){
					this.eventHub.$emit('displayAlert', this.CONSTANTS.ALREADY_NOTIFICATION);
					return;
				}
				else if(this.user.resourcesCount.push==0){
					this.eventHub.$emit('displayAlert', this.CONSTANTS.ZERO_CREDIT);
				}
				this.genericActionDialog = false;
				this.pushActionDialog = true;
			}
			else{
				this.currentAction.element = element;
				var model = undefined;
				//check if group
				if(moduleType == 'device')
				{
					if(element.devices)
					{
						this.currentAction.element.device = element.devices[0];
					}
				}
				var m = ArrayService.find(this.models, 'name', this.currentAction.element[moduleType].model);
				this.capabilitiesStatesForList = (m ? JSON.parse(JSON.stringify(m.capabilitiesStatesForList)) : this.CONSTANTS.DEFAULT_CAPABILITIES);				
				if(trigger){
					this.filterCapabilitiesStatesForList(this.currentAction.element[moduleType],m,'can_trigger');
				}
				else{
					this.filterCapabilitiesStatesForList(this.currentAction.element[moduleType],m,'executable');
				}
				if(this.triggerValue == true){
					this.titleOnOff = 'Choose Trigger';
					this.typeTriggerDialog = false;
					this.currentAction.element.trigger = true;
					this.use = 'scenario-trigger';
				}
				else{
					this.titleOnOff = 'Choose Action';
					this.deviceActionDialog = false;
					this.serviceActionDialog = false;
					this.currentAction.element.trigger = false;
					this.use = 'scenario-action';
				}
				if(this.capabilitiesStatesForList.length > 1 || m.name == 'rb' || m.name == 'ceiling_fan'|| m.name == 'wft'){
					this.chooseActionDialog = true;
				}
				else{
					if(this.currentAction.element[moduleType].model == 'camera' && _findIndex(this.actions, function(o) { return o.infos.model == 'camera'; }) != -1)
						this.eventHub.$emit('displayAlert', this.CONSTANTS.ALREADY_CAMERA);
					else
						this.createAction(this.capabilitiesStatesForList[0])
				}
			}
		},

		filterCapabilitiesStatesForList: function(moduleElt,m,value){
			let capabilities = (moduleElt.capabilities?moduleElt.capabilities:m.capabilities);
			var arr = [];
			this.capabilitiesStatesForList.forEach(function(elt, index){
				for(var i=0; i<capabilities.length; i++){
					if(elt.capabilityName == capabilities[i].capability && capabilities[i][value]){
						arr.push(elt);
					}
				}
			});
			this.capabilitiesStatesForList = JSON.parse(JSON.stringify(arr));
		},

		chooseValueToAction: function(element){
			this.currentAction.element = element;
			if(element.system == undefined)
			{
				var m = ArrayService.find(this.models, 'name', element.device.model);
				this.capabilitiesStatesForList = (m ? m.capabilitiesStatesForList : this.CONSTANTS.DEFAULT_CAPABILITIES);
			}
			else
				this.capabilitiesStatesForList = this.CONSTANTS.DEFAULT_CAPABILITIES;
			this.typeTriggerDialog = false;
			this.actionTemperatureDialog = true;
		},

		chooseCommandAction: function(element,moduleType){
			this.moduleType = moduleType;
			this.currentAction.element = element;
			if(element.system == undefined)
			{
				var m = ArrayService.find(this.models, 'name', element[moduleType].model);
				this.capabilitiesStatesForList = (m ? m.capabilitiesStatesForList : this.CONSTANTS.DEFAULT_CAPABILITIES);
			}
			else
				this.capabilitiesStatesForList = this.CONSTANTS.DEFAULT_CAPABILITIES;
			this.typeTriggerDialog = false;
			this.actionTelDialog = true;
		},

		chooseItemToTrigger: function(item,moduleType){
			this.triggerDeviceDialog=false;
			this.triggerServiceDialog=false;
			this.triggerValue = true;
			this.currentTrigger.trigger = item;
			this.moduleType = moduleType;
			if (moduleType == 'service' || item[moduleType].model == 'dmf' || item[moduleType].model == "rb"|| item[moduleType].model == 'smart_camera' || item[moduleType].model == "ceiling_fan"|| item[moduleType].model == "wft"
			|| item[moduleType].model == "vdp"
			|| item[moduleType].model == "battery_vdp"
			|| item[moduleType].model == "t21"
			|| item[moduleType].model == "two_colors_bulb"
			|| item[moduleType].model == "extl"
			|| item[moduleType].model == "oskkio-1"
			){
				this.chooseItemToAction(item,moduleType,true);
			}
			else if(item[moduleType].capabilities && item[moduleType].capabilities[0] && item[moduleType].capabilities[0].capability){
				switch(item[moduleType].capabilities[0].capability){
					case 'status' :
					case 'position_vertical' : 	
					case 'access' :
					case 'protection' :
										this.chooseItemToAction(item,moduleType);
										break;
					case 'ring' :
					case 'detect' :
										this.selectTypeTrigger('status',item);
										break;
					case 'temperature' :
										this.chooseValueToAction(item);
										break;							
					default : this.selectTypeTrigger('status',item);
				}
			}
			// For external plug
			else if( item[moduleType].model=='plug'){
				this.chooseItemToAction(item,moduleType);
			}
			else{
				this.chooseCommandAction(item,moduleType);
			}
		},

		createAction: function(a, infos){
			this.valueCapabilityDialog = false;
			if(this.triggerValue == true && (a.value != 'color_temperature')){
				this.selectTypeTrigger('status', this.currentAction.element, a);
				this.triggerValue = false;
			}
			else if(a.value == 'blink' || a.value == 'rgb'){
				this.selectedCapability = a;
				this.valueCapabilityDialog = true;
			}
			else if(a.value == 'color_temperature'){
				this.selectedCapability = a;
				var device = this.currentAction.element.device;
				this.colorTemperatureIndex = _findIndex(device.status, {'capability':'color_temperature'});
				if(this.colorTemperatureIndex!=-1)
				{
				  var colorTemperaturecapability = JSON.parse(JSON.stringify(_find(device.capabilities, {'capability':'color_temperature'})));
				  if(colorTemperaturecapability)
				  {
					this.selectedCapability.value = colorTemperaturecapability.default_value;
					this.colorTemperatureSlider.options.min = colorTemperaturecapability.min;
					this.colorTemperatureSlider.options.max = colorTemperaturecapability.max;
					this.colorTemperatureSlider.value = colorTemperaturecapability.default_value;
					this.colorTemperatureSlider.options.railStyle.backgroundImage = 'linear-gradient(to right, '+chroma.temperature(colorTemperaturecapability.min).hex()+', '+chroma.temperature(colorTemperaturecapability.max).hex()+')';
				  }
				}
				this.valueCapabilityDialog = true;
			}
			else{
				this.currentAction.type = 'command';
				var action = {
					'type': this.currentAction.type,
					'infos': (infos!=undefined ? infos : {
						'capability': a.capabilityName,
						'moduleId': this.currentAction.element.id,
						'name': this.currentAction.element.name,
						'icon': this.currentAction.element.icon
					})
				};
				if(a.value != 'trigger')
					action.infos.value = a.value;
				if(this.currentAction.element.devices){
					action.infos.model = 'group';
				}
				else if(this.currentAction.element.service)
				{
					action.infos.model = this.currentAction.element.service.model;
					action.infos.submodel = this.currentAction.element.service.submodel;
				}
				else{
					action.infos.model = (this.currentAction.element.device?this.currentAction.element.device.model:'plug');
				}

				//check if specific element dialog exist
				if(a && this[a.capabilityName+'Dialog']!=undefined)
				{
					this.currentElementForSpecificDialog = action;
					this.currentElementForSpecificDialog.element = 'action';
					this[a.capabilityName+'DialogDisplay']();
				}
				else
				{
					this.actions.push(action);
				}
			}
			this.chooseActionDialog = false;
		},

		formatPhoneNumber: function(m){
			return PhoneNumberService.formatStringToPhoneNumber(m);
		},

		/* TRIGGER */

		initTrigger: function()
		{
			var sm = this.scheduleModal;
			sm.repeat = false;
			//this.initDaysSelected();
			sm.timeValue=TimeService.getCurrentTime();
		},
		initDaysSelected: function()
		{
			var sm = this.scheduleModal;
			var today = new Date();
			var d = today.getDay();
			sm.selectedDays = [sm.days[d].value];
		},
		changeState: function()
		{
			if(this.scheduleModal.selectedType!='ALL')
				this.initDaysSelected();
		},

		addTrigger: function(){
			this.initTrigger();
			this.typeTriggerDialog = true;
		},

		selectTypeTrigger: function(type, item, a){
			this.currentTrigger.type = type;
			if(type=='schedule')
			{
				this.scheduleModal.selectedDays = ['SUNDAY','MONDAY','TUESDAY','WEDNESDAY','THURSDAY','FRIDAY','SATURDAY'];
				this.scheduleModal.timeValue =  TimeService.getCurrentTime();
				this.scheduleModal.repeat = false;
				this.scheduleDialog = true;
			}
			else if(type=='device')
			{
				this.triggerDeviceDialog = true;
			}
			else if(type=='service')
			{
				this.triggerServiceDialog = true;
			}
			else
			{
				this.createTrigger(item, a);
			}
			this.typeTriggerDialog = false;
		},

		validateNumber: function(){
			if( parseInt(this.valueTemperature) < 50 && this.temperatureSign != '' ){
				return true
			}
			else{
				this.eventHub.$emit('displayAlert',  this.CONSTANTS.TEMPERATURE_ERROR);
				return false
			}
		},

		probeTrigger: function(sign, value, item){
			if(this.validateNumber()){
				this.currentTrigger.type = 'status';
				this.actionTemperatureDialog = false;
				var values = {};
				values.value = value;
				values.capabilityName = 'temperature';
				values.sign = sign;
				this.createTrigger(item, values);
				this.valueTemperature = 0;
				this.temperatureSign = '';
			}
		},

		telTrigger: function(item, status, subItem){
			this.currentTrigger.type = 'status';
			var value = {};
			value.value = status;
			value.capabilityName = 'status';
			this.actionTelDialog = false;
			this.createTrigger(item, value, subItem);
		},

		createTrigger: function(item, a, subItem){
			var trigger = {
				'type': this.currentTrigger.type
			}
			var triggerInfos = {};
			if(this.currentTrigger.type=='schedule')
			{
				triggerInfos = {
					'time': this.scheduleModal.timeValue,
					'days': this.scheduleModal.selectedDays,
					'repeat': this.scheduleModal.repeat
				}
			}
			else
			{
				var module = (item.device?item.device:item.service);
				triggerInfos = {
					'moduleId': (subItem!=undefined?subItem.id:item.id),
					'id': (subItem!=undefined?subItem.id:item.id),
					'name': (subItem!=undefined?subItem.name:item.name),
					'model': module.model
				}
				if(module.model == "rb" || module.model == "ceiling_fan"|| module.model == "wft"){
					triggerInfos.name = a.name;
					trigger.type = "status";
					triggerInfos.id = a.moduleId;
					triggerInfos.moduleId = a.moduleId;
				}
				//Check if module has a system or at least a status (for services)
				else if(a && ((module.systems && module.systems[0]) || module.status)){
					if(a.value != 'trigger')
						triggerInfos.value = a.value;
				}
				var properties = this.getTriggerCapability(module.model, a);
				if(module.systems &&  module.systems[0] == 'meteo'){
					triggerInfos.value = a.value;
					if(a.sign == "Greater than"){
						properties.operation = 'HIGHER';
					}
					else{
						properties.operation = 'LOWER';
					}
				}
				triggerInfos = Object.assign(triggerInfos, properties);
			}
			trigger.infos = triggerInfos;
			//check if specific element dialog exist
			if((a)&&(this[a.capabilityName+'Dialog']!=undefined))
			{
				this.currentElementForSpecificDialog = trigger;
				this.currentElementForSpecificDialog.element = 'trigger';
				this[a.capabilityName+'DialogDisplay']();
			}
			else
			{
				this.triggers.push(trigger);
			}
			this.scheduleDialog = false;
		},


		getTriggerCapability: function(model, a)
		{
			var properties = {
				'operation' : 'EQUAL'
			}
			if(a && a.capabilityName){
				properties.capability = a.capabilityName;
			}
			else{
				switch(model)
				{
					case 'alarm' :
									properties.capability = 'alert';
									break;
					case 'car' :
									properties.capability = 'ring';
									break;
					case 'camera' :
									properties.capability = 'show';
									break;
					case 'dem' :
					case 'wa' :
									properties.capability = 'detect';
									break;
					case 'dmf' :
									properties.capability = 'access';
									break;
					case 'ycb' :
					case 'frx' :
					case 'prf' :
					case 'prfx' :
					case 'plug' :
					case 'bulb' :
					case 'cmr' :
					case 'tel' :
					case 'trf' :
					case 'ale' :
					case 'ecr' :
									properties.capability = 'status';
									break;
					case 'sthi' :
									properties.capability = 'temperature';
									break;
					case 'mmv' :
									properties.capability = 'position_vertical';
									break;
					case 'rb' :
					case 'ceiling_fan' :
					case 'wft' :
									properties.capability = 'trigger';
									break;
				}
			}
			return properties;
		},

		getTriggerTitle: function(t){
			if(t.type=='schedule')
				return t.type;
			else if (t.infos.model == 'rb'){
				return t.infos.name
			}
			else
			{
				var m = DeviceResource.getModule(t.infos.moduleId,this.devices,this.subDevices);
				return (m.module?(m.module.notificationName?m.module.notificationName:m.module.name):t.infos.name);
			}
		},

		getSubTitleTrigger: function(t){
			if(t.type=='schedule')
			{
				var _this =this;
				var infosDays = t.infos.days.map(function(d) {
					return _this.$gettext(d.toLowerCase());
				});
				return this.$gettext('Trigger at')+' '+t.infos.time+' '+(t.infos.days.length==7?this.$gettext('everyday'):infosDays.toString())+(t.infos.repeat?' ('+this.$gettext('Repeat')+')':'');

			}
			else if(t.infos.capability == 'temperature')
			{
				return this.appConstants.ACTIONS_TRIGGER[t.infos.capability].subTitle +' ('+this.$gettext((t.infos.operation == 'HIGHER' ? 'Greater than':'Less than'))+' '+t.infos.value+'°C)';
			}
			else 
			{
				return this.getSubTitle(t,this.appConstants.ACTIONS_TRIGGER);
			}
		},

		saveTime: function(){
			var triggerTimePicker = this.$refs['trigger-time-picker'];
			triggerTimePicker.selectingHour=true;
			triggerTimePicker.save();
		},

		cancelTime: function(){
			var triggerTimePicker = this.$refs['trigger-time-picker'];
			triggerTimePicker.selectingHour=true;
			triggerTimePicker.cancel();
		},

		/* SCENARIO */

		validateCheck: function(){
			if(!this.$v.name.required)
			{
				this.eventHub.$emit('displayAlert',  this.CONSTANTS.EMPTY_NAME_ERROR);
				return false;
			}
			return true;
		},

		/*validateName: function(){
			if(this.validateCheck())
			{
				this.step=parseInt(this.step)+1;
				//Select first room 
				if(this.plant.zones.length>0 && !this.roomId)
				{

					this.roomId = this.plant.zones[0].id;
				}
			}
		},*/
		
		getScenario: function(){
			return {
				'name': this.name,
				'visible': true,
				'locationId': this.roomId,
				'actions': this.actions,
				'triggers': this.triggers,
				'icon':null,
				'scenario':
				{
					'status':
					[
						{
							capability: 'active',
							value: 'enable'
						}
					],
					'actions': this.actions,
					'triggers': this.triggers
				}			
			}
		},

		modifyScenario: function(scenario, id){
			scenario.active = "enable";
			scenario.id = id;
			scenario.type = null;
		},

		changeScenario: function(scenario){
			var s = this.scenarios[this.index];
			s.name = scenario.name;
			s.triggers = scenario.triggers;
			s.actions = scenario.actions;
			s.locationId = scenario.locationId;
		},

		changeScenarioRoom: function(zone){
			this.roomId = zone.id;
		},

		validate: function(){
			if(this.plant.zones.length>0 && !this.roomId)
			{

				this.roomId = this.plant.zones[0].id;
			}
			var selectedZone = _find(this.plant.zones, { 'id': this.roomId });
			if(this.validateCheck())
			{
				var scenario = this.getScenario();
				var _this = this;
				this.loading = true;
				if(this.scenario.id){
					ScenarioResource.edit(scenario, this.scenario.id).then(
						function(response) {
							_this.loading = false;
							_this.modifyScenario(scenario, _this.scenario.id);
							_this.changeScenario(scenario);
							_this.eventHub.$emit('displayAlert',  _this.CONSTANTS.SCENARIO_EDIT_SUCCESS, 'success');
							_this.eventHub.$emit('scenariosDialogEdit');
					}).catch(
						function(e) {
							if(e == 'Error: Network Error')
								_this.eventHub.$emit('displayAlert', _this.CONSTANTS.NO_NETWORK);
							else if(e.response && e.response.status == 401)
								_this.eventHub.$emit('logout');
							else
								_this.eventHub.$emit('displayAlert',  _this.CONSTANTS.SCENARIO_EDIT_ERROR);
							_this.loading = false;							
					});
				}
				else{
					ScenarioResource.add(scenario).then(
						function(response) {
							_this.loading = false;
							//scenario.id = response.data.id;
							_merge(scenario,response.data);
							if(selectedZone.modules==undefined)
							{
								selectedZone.modules = [];
							}
							selectedZone.modules.push(scenario);
							_this.eventHub.$emit('displayAlert',  _this.CONSTANTS.SCENARIO_ADD_SUCCESS, 'success');
							_this.eventHub.$emit('scenariosDialogValidate',scenario);
					}).catch(
						function(e) {
							_this.loading = false;
							if(e == 'Error: Network Error')
								_this.eventHub.$emit('displayAlert', _this.CONSTANTS.NO_NETWORK);
							else if(e.response && e.response.status == 401)
								_this.eventHub.$emit('logout');
							else if(e.response && [402].indexOf(e.response.status)!=-1)
								_this.eventHub.$emit('displayAlertKey', 'unauthorized');  
							else
								_this.eventHub.$emit('displayAlert',  _this.CONSTANTS.SCENARIO_ADD_ERROR);
					});
				}
			}
		},

		closeChooseAction: function(){
			this.triggerValue = false;
			this.chooseActionDialog = false;
		},

		verifyExecutable: function(m){
			if(m.device){
				if(m.device.capabilities){
					for(var i=0; i < m.device.capabilities.length; i++){
						if(m.device.capabilities[i].executable == true)
							return true
					}
				}
			}
		},

		verifyTriggerable: function(m){
			if(m.device){
				if(m.device.capabilities){
					for(var i=0; i < m.device.capabilities.length; i++){
						if(m.device.capabilities[i].can_trigger == true)
							return true
					}
				}
			}
		},

		createScenarioItem: function(widget, status, subItem, use){
			var itemType = use.replace('scenario-','');
			var infos =
			{
				'capability': '',
				'moduleId': '',
				'name': (subItem!=undefined?subItem.name:widget.device.name)
			};
			switch(widget.device.model)
			{
				case 'rb' :
				case 'ceiling_fan' : 
				case 'wft' :
					infos.capability = 'trigger';
					infos.moduleId = subItem.id;
					infos.value = 'trigger';
					break;
			}
			if(this.use == 'scenario-action')
				this.createAction(widget,infos);
			else
				this.createTrigger(widget,infos);
			this.chooseActionDialog = false;
		},

		speedDialogDisplay: function(){
			this.speedDialog = true;
		},
		speedDialogValidate: function(){
			this.currentElementForSpecificDialog.infos.value = this.sliderValue;
			this[this.currentElementForSpecificDialog.element+'s'].push(JSON.parse(JSON.stringify(this.currentElementForSpecificDialog)));
			this.speedDialog = false;
		},
		openSmsMarket: function(){
			var lang = (Language.getUserLanguage()=='fr'?'fr':'en');
			WindowService.open(Global.WEBSITE_STORE+'/notifications-'+lang,'_blank',this.inApp);
		},
	
		checkDisplaySMSChoice: function(){
			return (this.$store.state.variantManager.class.alerts && _find(this.$store.state.variantManager.class.alerts, { name: 'SMS' })==undefined ? false:true);
		},
		
	}
}
